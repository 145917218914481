import {
  type InitConfiguration,
  INTAKE_SITE_EU1,
  INTAKE_SITE_STAGING,
  INTAKE_SITE_US1,
  INTAKE_SITE_US1_FED,
} from '@datadog/browser-core';
import { datadogLogs, type LogsEvent } from '@datadog/browser-logs';
import { datadogRum, type RumEvent } from '@datadog/browser-rum';

export type LoggingServiceConfig = {
  rum: {
    applicationId: string;
    clientToken: string;
    site: string;
    service: string;
    env: string;
    /** needs to match the version specified in sourcemap uploads to DataDog */
    version: string;
  };
  browserLog: {
    clientToken: string;
    site: string;
    service: string;
    env: string;
    /** needs to match the version specified in sourcemap uploads to DataDog */
    version: string;
  };
};

export type LoggingService = ReturnType<typeof createLoggingService>;

export function createLoggingService({
  config,
  isRumEnabled,
  rumEventFilter,
  browserLogEventFilter,
}: {
  config: LoggingServiceConfig;
  isRumEnabled: boolean;
  /**
   * Return `false` to prevent the event from being sent to the remote logging service.
   */
  rumEventFilter?: (event: RumEvent) => boolean;
  /**
   * Return `false` to prevent the event from being sent to the remote logging service.
   */
  browserLogEventFilter?: (event: LogsEvent) => boolean;
}) {
  if (isRumEnabled) {
    if (!isDatadogSite(config.rum.site)) {
      throw new Error(`Invalid Datadog RUM intake site: ${config.rum.site}`);
    }

    datadogRum.init({
      beforeSend: rumEventFilter,
      applicationId: config.rum.applicationId,
      clientToken: config.rum.clientToken,
      site: config.rum.site,
      service: config.rum.service,
      env: config.rum.env,
      version: config.rum.version,
      sessionSampleRate: 100, // monitor 100% user sessions
      sessionReplaySampleRate: 1, // collect 1% replay sessions
      // `trackLongTasks` and `trackResources` are enabled by default starting in v6.x of the DD RUM SDK.
      // We disable those seetings here to mimic the behavior of the v5.x SDK. We can easily enable them
      // later if we decide they are useful / necessary.
      trackLongTasks: false,
      trackResources: false,
      trackUserInteractions: true,
      // By default, Datadog will try to mask all HTML text, user input, images, links and data-* attributes
      // from the RUM session replay. We only need to hide user input, so we adjust the following
      // property accordingly.
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  if (!isDatadogSite(config.browserLog.site)) {
    throw new Error(`Invalid Datadog browser log intake site: ${config.browserLog.site}`);
  }

  datadogLogs.init({
    clientToken: config.browserLog.clientToken,
    // By default, the browser logger will forward console.error logs, uncaught exceptions and network errors
    // to Datadog. We want to rely on RUM for that behavior, so we disable it here.
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env: config.browserLog.env,
    version: config.browserLog.version,
    service: config.browserLog.service,
    site: config.browserLog.site,
    beforeSend: browserLogEventFilter,
  });

  const { addError } = datadogRum;
  const { logger } = datadogLogs;

  return {
    clearUser: () => {
      datadogLogs.clearUser();
      datadogRum.clearUser();
    },
    critical: (message: string, context?: Record<string, any>) => {
      logger.critical(message, context);
    },
    debug: (message: string, context?: Record<string, any>) => {
      logger.debug(message, context);
    },
    error: (err: Error, context?: Record<string, any>) => {
      // log to rum
      addError(err, context);

      // log to logs
      logger.error(err.message, context, err);
    },
    info: (message: string, context?: Record<string, any>) => {
      logger.info(message, context);
    },
    setUser: ({ id }: { id: string }) => {
      datadogLogs.setUserProperty('id', id);
      datadogRum.setUserProperty('id', id);
    },
    warn: (message: string, context?: Record<string, any>) => {
      logger.warn(message, context);
    },
  };
}

function isDatadogSite(site: string): site is InitConfiguration['site'] {
  return (
    site === INTAKE_SITE_US1 ||
    site === INTAKE_SITE_EU1 ||
    site === INTAKE_SITE_STAGING ||
    site === INTAKE_SITE_US1_FED
  );
}
